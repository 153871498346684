
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes
	{
		path: '/bitacora',
		name: 'bitacora',
		component: () => import('./pages/home/bitacora.vue'),
		props: true
	},
	{
		path: '/ramps-dashboard',
		name: 'rampsdashboard',
		component: () => import('./pages/ramps/rampsdashboard.vue'),
	},
	{
		path: '/ramps-dashboards',
		name: 'rampsdashboards',
		component: () => import('./pages/ramps/rampsdashboard.vue'),
	},
	{
		path: '/ramps-screen',
		name: 'rampsscreen',
		component: () => import('./pages/ramps/rampsscreen.vue'),
		meta: {
		  hideSidebar: true, // Para ocultar el menú lateral si es necesario
		},
	},

//accesslog routes
			{
				path: '/accesslog/:fieldName?/:fieldValue?',
				name: 'accessloglist',
				component: () => import('./pages/accesslog/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/accesslog/view/:id', 
				name: 'accesslogview', 
				component: () => import('./pages/accesslog/view.vue'), 
				props: true
			},


			//carrieraccesslog routes
			{
				path: '/carrier_accesslog/:fieldName?/:fieldValue?',
				name: 'carrier_accessloglist',
				component: () => import('./pages/carrieraccesslog/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/carrier_accesslog/view/:id', 
				name: 'carrier_accesslogview', 
				component: () => import('./pages/carrieraccesslog/view.vue'), 
				props: true
			},

//accessrequestpriorities routes
			{
				path: '/accessrequestpriorities/:fieldName?/:fieldValue?',
				name: 'accessrequestprioritieslist',
				component: () => import('./pages/accessrequestpriorities/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/accessrequestpriorities/view/:id', 
				name: 'accessrequestprioritiesview', 
				component: () => import('./pages/accessrequestpriorities/view.vue'), 
				props: true
			},
		
			{ 
				path: '/accessrequestpriorities/add', 
				name: 'accessrequestprioritiesadd', 
				component: () => import('./pages/accessrequestpriorities/add.vue'), 
				props: true
			},
	
			{ 
				path: '/accessrequestpriorities/edit/:id', 
				name: 'accessrequestprioritiesedit', 
				component: () => import('./pages/accessrequestpriorities/edit.vue'), 
				props: true
			},
		

//accessrequests routes
			{
				path: '/accessrequests/:fieldName?/:fieldValue?',
				name: 'accessrequestslist',
				component: () => import('./pages/accessrequests/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/accessrequests/view/:id', 
				name: 'accessrequestsview', 
				component: () => import('./pages/accessrequests/view.vue'), 
				props: true
			},
		
			{ 
				path: '/accessrequests/add', 
				name: 'accessrequestsadd', 
				component: () => import('./pages/accessrequests/add.vue'), 
				props: true
			},
	
			{ 
				path: '/accessrequests/edit/:id', 
				name: 'accessrequestsedit', 
				component: () => import('./pages/accessrequests/edit.vue'), 
				props: true
			},
		
			{
				path: '/accessrequests/cardlist1/:fieldName?/:fieldValue?',
				name: 'accessrequestscardlist1',
				component: () => import('./pages/accessrequests/cardlist1.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/accessrequests/cardlist2/:fieldName?/:fieldValue?',
				name: 'accessrequestscardlist2',
				component: () => import('./pages/accessrequests/cardlist2.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/accessrequests/cardlist3/:fieldName?/:fieldValue?',
				name: 'accessrequestscardlist3',
				component: () => import('./pages/accessrequests/cardlist3.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/accessrequests/cardlist4/:fieldName?/:fieldValue?',
				name: 'accessrequestscardlist4',
				component: () => import('./pages/accessrequests/cardlist4.vue'), 
				props: route => passRouteToProps(route)
			},

//carrierrequest routes
			{
				path: '/carrierrequests/:fieldName?/:fieldValue?',
				name: 'carrierrequestslist',
				component: () => import('./pages/carrierrequests/list.vue'), 
				props: route => passRouteToProps(route)
			},

			{ 
				path: '/carrierrequests/view/:id', 
				name: 'carrierrequestsview', 
				component: () => import('./pages/carrierrequests/view.vue'), 
				props: true
			},

			{ 
				path: '/carrierrequests/add', 
				name: 'carrierrequestsadd', 
				component: () => import('./pages/carrierrequests/add.vue'), 
				props: true
			},

			{ 
				path: '/carrierrequests/edit/:id', 
				name: 'carrierrequestsedit', 
				component: () => import('./pages/carrierrequests/edit.vue'), 
				props: true
			},

//accessrequeststatus routes
			{
				path: '/accessrequeststatus/:fieldName?/:fieldValue?',
				name: 'accessrequeststatuslist',
				component: () => import('./pages/accessrequeststatus/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/accessrequeststatus/view/:id', 
				name: 'accessrequeststatusview', 
				component: () => import('./pages/accessrequeststatus/view.vue'), 
				props: true
			},
		
			{ 
				path: '/accessrequeststatus/add', 
				name: 'accessrequeststatusadd', 
				component: () => import('./pages/accessrequeststatus/add.vue'), 
				props: true
			},
	
			{ 
				path: '/accessrequeststatus/edit/:id', 
				name: 'accessrequeststatusedit', 
				component: () => import('./pages/accessrequeststatus/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//repsestatus routes
			{
				path: '/repsestatus/:fieldName?/:fieldValue?',
				name: 'repsestatuslist',
				component: () => import('./pages/repsestatus/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/repsestatus/view/:id', 
				name: 'repsestatusview', 
				component: () => import('./pages/repsestatus/view.vue'), 
				props: true
			},
		
			{ 
				path: '/repsestatus/add', 
				name: 'repsestatusadd', 
				component: () => import('./pages/repsestatus/add.vue'), 
				props: true
			},
	
			{ 
				path: '/repsestatus/edit/:id', 
				name: 'repsestatusedit', 
				component: () => import('./pages/repsestatus/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
//Employees routes
			{
				path: '/employees/:fieldName?/:fieldValue?',
				name: 'employeeslist',
				component: () => import('./pages/employees/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/employees/view/:id', 
				name: 'employeesview', 
				component: () => import('./pages/employees/view.vue'), 
				props: true
			},
		
			{ 
				path: '/employees/add', 
				name: 'employeesadd', 
				component: () => import('./pages/employees/add.vue'), 
				props: true
			},
	
			{ 
				path: '/employees/edit/:id', 
				name: 'employeesedit', 
				component: () => import('./pages/employees/edit.vue'), 
				props: true
			},
		

//userdepts routes
			{
				path: '/userdepts/:fieldName?/:fieldValue?',
				name: 'userdeptslist',
				component: () => import('./pages/userdepts/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/userdepts/view/:id', 
				name: 'userdeptsview', 
				component: () => import('./pages/userdepts/view.vue'), 
				props: true
			},
		
			{ 
				path: '/userdepts/add', 
				name: 'userdeptsadd', 
				component: () => import('./pages/userdepts/add.vue'), 
				props: true
			},
	
			{ 
				path: '/userdepts/edit/:id', 
				name: 'userdeptsedit', 
				component: () => import('./pages/userdepts/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'usersuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

//userstatus routes
			{
				path: '/userstatus/:fieldName?/:fieldValue?',
				name: 'userstatuslist',
				component: () => import('./pages/userstatus/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/userstatus/view/:id', 
				name: 'userstatusview', 
				component: () => import('./pages/userstatus/view.vue'), 
				props: true
			},
		
			{ 
				path: '/userstatus/add', 
				name: 'userstatusadd', 
				component: () => import('./pages/userstatus/add.vue'), 
				props: true
			},
	
			{ 
				path: '/userstatus/edit/:id', 
				name: 'userstatusedit', 
				component: () => import('./pages/userstatus/edit.vue'), 
				props: true
			},
		

//vendors routes
			{
				path: '/vendors/:fieldName?/:fieldValue?',
				name: 'vendorslist',
				component: () => import('./pages/vendors/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vendors/view/:id', 
				name: 'vendorsview', 
				component: () => import('./pages/vendors/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vendors/add', 
				name: 'vendorsadd', 
				component: () => import('./pages/vendors/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vendors/edit/:id', 
				name: 'vendorsedit', 
				component: () => import('./pages/vendors/edit.vue'), 
				props: true
			},
//accessvip routes
			{
				path: '/accessvip/:fieldName?/:fieldValue?',
				name: 'accessviplist',
				component: () => import('./pages/accessvip/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/accessvip/view/:id', 
				name: 'accessvipview', 
				component: () => import('./pages/accessvip/view.vue'), 
				props: true
			},
		
			{ 
				path: '/accessvip/add', 
				name: 'accessvipadd', 
				component: () => import('./pages/accessvip/add.vue'), 
				props: true
			},
	
			{ 
				path: '/accessvip/edit/:id', 
				name: 'accessvipedit', 
				component: () => import('./pages/accessvip/edit.vue'), 
				props: true
			},
		

//vendorslevels routes
			{
				path: '/vendorslevels/:fieldName?/:fieldValue?',
				name: 'vendorslevelslist',
				component: () => import('./pages/vendorslevels/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vendorslevels/view/:id', 
				name: 'vendorslevelsview', 
				component: () => import('./pages/vendorslevels/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vendorslevels/add', 
				name: 'vendorslevelsadd', 
				component: () => import('./pages/vendorslevels/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vendorslevels/edit/:id', 
				name: 'vendorslevelsedit', 
				component: () => import('./pages/vendorslevels/edit.vue'), 
				props: true
			},
		

//vendorsstatus routes
			{
				path: '/vendorsstatus/:fieldName?/:fieldValue?',
				name: 'vendorsstatuslist',
				component: () => import('./pages/vendorsstatus/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vendorsstatus/view/:id', 
				name: 'vendorsstatusview', 
				component: () => import('./pages/vendorsstatus/view.vue'), 
				props: true
			},
		
			{ 
				path: '/vendorsstatus/add', 
				name: 'vendorsstatusadd', 
				component: () => import('./pages/vendorsstatus/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vendorsstatus/edit/:id', 
				name: 'vendorsstatusedit', 
				component: () => import('./pages/vendorsstatus/edit.vue'), 
				props: true
			},
		

			{ 
				path: '/requestskanban', 
				name: 'requestskanban', 
				component: () => import('././pages/custom/requestskanban.vue'), 
				props: true
			},
	
	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
			{ 
				path: '/index/guest_request', 
				name: 'guest_request', 
				component: () => import('./pages/index/guestrequest.vue'), 
				props: true
			},
			{ 
				path: '/index/aviso_privacidad', 
				name: 'aviso_privacidad', 
				component: () => import('./pages/index/PrivacyNotice.vue'), 
				props: true
			},
			{ 
				path: '/index/welcome', 
				name: 'welcome', 
				component: () => import('./pages/index/welcomecompany.vue'), 
				props: true
			},
			{ 
				path: '/index/qrreader', 
				name: 'qrreader', 
				component: () => import('./pages/index/qrreader.vue'), 
				props: true
			},
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
	,
	{
		path: "/index/rampsscreen",
		alias: "/ramps-screen",
		name: "rampsdashboard",
		component: () => import('./pages/ramps/rampsscreen.vue'),
		props: route => passRouteToProps(route)
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		if (auth.isVendor) {
			routes.push({
				path: "/",
				alias: "/access",
				name: "access",
				component: () => import('./pages/accesslog/list.vue'), 
				props: route => passRouteToProps(route)
			});
		}
		if (auth.isVendorCarrier) {
			routes.push({
				path: "/",
				alias: "/carrierrequests",
				name: "carrierrequests",
				component: () => import('./pages/carrierrequests/list.vue'), 
				props: route => passRouteToProps(route)
			});
		}
		if (auth.isReceiptSupervisor) {
			routes.push({
				path: "/",
				alias: "/ramps-dashboards",
				name: "rampsdashboard",
				component: () => import('./pages/ramps/rampsdashboard.vue'),
				props: route => passRouteToProps(route)
			});
		}
		if (auth.isUserScreen) {
			routes.push({
				path: "/",
				alias: "/ramps-screen",
				name: "rampsscreen",
				component: () => import('./pages/ramps/rampsscreen.vue'),
				props: route => passRouteToProps(route)
			});
		}
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user

			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
